.wrapper {
  position: absolute;
  top: 55px;
  margin-left: 105px;
}

.list {
  display: flex;
  font-weight: 500;
}

.arrowRight {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.link:hover > * {
  text-decoration: underline;
}

.current {
  font-weight: 400;
}

@media (max-width: 1023px) {
  .wrapper {
    top: 110px;
    margin-left: 16px;
  }
}

@media (max-width: 500px) {
  .list {
    text-align: center;
  }
}
