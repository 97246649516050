.logo {
  position: relative;
  display: flex;
  max-width: 190px;
  height: 100%;
  max-height: 48px;
}

.image {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 48px;
  object-fit: cover;
}

@media (max-width: 1023px) {
  .image {
    height: 31px;
  }
}
