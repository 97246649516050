.buttonList {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.listButton > * {
  width: 100%;
}

.listButton:not(:last-child) {
  margin-bottom: 20px;
}

.linkButton {
  display: flex;
  width: 100% !important;
  height: 60px;
}

.tooltip {
  max-width: 230px;
}
