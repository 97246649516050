.grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 120px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.badge {
  width: 52px;
  height: 63px;
  margin-bottom: 18px;
}

.title,
.description {
  max-width: 230px;
}

.title {
  margin-bottom: 8px;
}

.divider {
  width: 32px;
  height: 64px;
  align-self: center;
}

@media (max-width: 1300px) {
  .divider {
    display: none;
  }
}

@media (max-width: 1100px) {
  .grid {
    flex-direction: column;
    margin-top: 60px;
  }

  .item {
    margin-bottom: 40px;
  }

  .title,
  .description {
    max-width: 350px;
  }
}

@media (max-width: 800px) {
  .grid {
    flex-direction: column;
    margin-top: 60px;
  }

  .badge {
    min-width: 57px;
    margin-right: 20px;
  }

  .item {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    text-align: left;
  }

  .title,
  .description {
    max-width: 100%;
  }
}
