.input {
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
  border: 2px solid;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  height: 50px;
  outline: none;
  -webkit-background-clip: text !important;
}

.input.withButton {
  padding-right: 45px;
}

.input.readOnly {
  cursor: not-allowed;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.4rem;
  font-weight: 600;
}

.inputWrapper {
  position: relative;
}

.clearButton {
  position: absolute;
  display: flex;
  top: 50%;
  right: 10px;
  z-index: 1;
  transform: translateY(-50%);
  padding: 3px 6px 0;
}

.errorMessage {
  margin-top: 5px;
}
