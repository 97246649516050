.wrapper {
  position: absolute;
  display: flex;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.dot:not(:last-child) {
  margin-right: 5px;
}

.dot.active {
}
