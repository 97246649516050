.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.line {
  --lineHeight: 3px;
  --animationTime: 0.4s;
  position: relative;
  width: 100%;
  height: var(--lineHeight);
  background-color: var(--white);
  border-radius: 3px;
}

.crossShape .line:nth-child(1) {
  animation: lineToCross1 var(--animationTime) ease-in-out forwards;
}

.crossShape .line:nth-child(2) {
  animation: lineToCross2 var(--animationTime) ease-in-out forwards;
}

.crossShape .line:nth-child(3) {
  animation: lineToCross3 var(--animationTime) ease-in-out forwards;
}

.burgerShape .line:nth-child(1) {
  animation: crossToLine1 var(--animationTime) ease-in-out forwards;
}

.burgerShape .line:nth-child(2) {
  animation: crossToLine2 var(--animationTime) ease-in-out forwards;
}

.burgerShape .line:nth-child(3) {
  animation: crossToLine3 var(--animationTime) ease-in-out forwards;
}

@keyframes lineToCross1 {
  0% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }

  55% {
    top: 50%;
    left: 0;
    transform: translate(0, var(--lineHeight) * -1);
  }

  60% {
    top: 50%;
    left: 0;
    transform: translate(0, var(--lineHeight) * -1) rotate(0deg);
  }

  100% {
    top: 50%;
    left: -50%;
    transform: translate(50%, calc(50% - var(--lineHeight))) rotate(-45deg);
  }
}

@keyframes lineToCross3 {
  0% {
    bottom: 0;
    right: 0;
    transform: translate(0, 0);
  }

  55% {
    bottom: 50%;
    right: 0;
    transform: translate(0, var(--lineHeight));
  }

  60% {
    right: 0;
    bottom: 50%;
    transform: translate(0, 0) rotate(0deg);
  }

  100% {
    right: -50%;
    bottom: 50%;
    transform: translate(-50%, calc(-50% + var(--lineHeight))) rotate(45deg);
  }
}

@keyframes lineToCross2 {
  0% {
    opacity: 1;
  }

  59% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes crossToLine1 {
  0% {
    top: 50%;
    left: -50%;
    transform: translate(50%, calc(50% - var(--lineHeight))) rotate(-45deg);
  }
  55% {
    top: 50%;
    left: 0;
    transform: translate(0, var(--lineHeight) * -1) rotate(0deg);
  }
  60% {
    top: 50%;
    left: 0;
    transform: translate(0, var(--lineHeight) * -1);
  }

  100% {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}

@keyframes crossToLine3 {
  0% {
    right: -50%;
    bottom: 50%;
    transform: translate(-50%, calc(-50% + var(--lineHeight))) rotate(45deg);
  }

  55% {
    right: 0;
    bottom: 50%;
    transform: translate(0, 0) rotate(0deg);
  }
  60% {
    bottom: 50%;
    right: 0;
    transform: translate(0, var(--lineHeight));
  }

  100% {
    bottom: 0;
    right: 0;
    transform: translate(0, 0);
  }
}

@keyframes crossToLine2 {
  0% {
    opacity: 0;
  }
  59% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
