.sidebar {
  position: relative;
  width: 100%;
  max-width: var(--sidebarMaxWidth);
  height: auto;
  min-height: 100vh;
  padding: 40px;
  box-sizing: border-box;
}

.innerShadow {
  box-shadow: inset -36px 0px 60px rgb(0 0 0 / 5%);
}

.stickyWrapper {
  top: 40px;
  position: sticky;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logout {
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 500;
}

.backButtonWrapper {
  margin-top: 100px;
}

.backButton {
  display: flex;
  width: max-content;
  padding: 13px 20px;
  box-sizing: border-box;
  border: 2px solid var(--white);
  font-size: 1.6rem;
  font-weight: 500;
}

.backButton:focus-visible {
  outline: none;
}

.backIcon {
  width: 17px;
  height: auto;
  margin-right: 10px;
}

.contentWrapper {
  max-width: 585px;
  margin-top: 175px;
  padding: 0 55px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 25px;
  padding: 0 115px 0 0;
  font-size: 2.5rem !important;
  font-weight: 700;
  line-height: 3.4rem;
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.itemTextWrapper {
  margin-left: 15px;
}

.itemTitle {
  margin-bottom: 4px;
  font-weight: 700;
  line-height: 2.4rem;
}

@media (max-width: 1200px) {
  .sidebar {
    padding: 20px 20px;
  }
}

@media (min-width: 1024px) {
  .sidebar {
    max-width: max-content;
  }
}
