.wrapper {
  position: relative;
  padding: 40px 50px;
}

.loginCta {
  position: absolute;
  right: 50px;
  font-size: 1.4rem;
}

.ctaLink {
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 500;
}

.ctaLink > * {
  text-decoration: underline;
}

.content {
  width: 350px;
  margin: 200px auto 0;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .wrapper {
    padding: 40px var(--mobileSidePadding);
  }

  .content {
    max-width: 350px;
    width: auto;
    margin-top: 20px;
  }

  .loginCta {
    right: var(--mobileSidePadding);
    margin-top: -20px;
  }
}
