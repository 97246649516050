.title {
  margin-bottom: 100px;
  font-size: 3rem;
}

.textbox,
.alert {
  max-width: 350px;
}

.inlineInputWrapper {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.textbox {
  width: 100%;
}

.inlineInput {
  max-width: 350px;
  width: 100%;
  margin-right: 20px;
}

.textboxWrapper {
  margin-bottom: 20px;
}

.alert {
  margin-top: 30px;
  box-sizing: border-box;
}

.text {
  max-width: 950px;
  padding-right: 30px;
}

.cta {
  margin-left: auto;
}

@media (max-width: 1023px) {
  .title {
    margin-bottom: 60px;
  }

  .inlineInputWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .inlineInput {
    margin-bottom: 20px;
  }

  .cta {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (max-width: 599px) {
  .textbox,
  .inlineInput {
    max-width: 100%;
  }

  .cta {
    width: 100% !important;
  }

  .textbox {
    margin-bottom: 20px;
  }
}

@media (max-width: 350px) {
  .title {
    margin-top: 110px;
  }
}
