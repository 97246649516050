.content {
  width: 350px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin: 20px 0;
  line-height: 3.6rem;
}

.link {
  text-decoration: underline;
}

.wrapper {
  position: relative;
  padding: 40px 50px;
}

.button {
  width: 100% !important;
  margin-top: 30px;
}

.linkWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .content {
    margin-top: 5px;
  }

  .wrapper {
    padding: 40px var(--mobileSidePadding);
  }
}
