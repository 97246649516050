html {
  font-size: 62.5%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 2.2rem;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
dd,
dl {
  margin: 0;
}

dt,
dd {
  display: inline-block;
}

ul {
  margin: 0;
  list-style: none;
  padding-left: 0;
}

hr {
  margin: 0;
  border: none;
}

button:focus-visible {
  outline: none;
  border: 2px solid !important;
}

/* Removes native "clear text" and "password reveal" buttons from Edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
