.wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 459px) {
  .wrapper {
    font-size: 2rem;
  }
}
