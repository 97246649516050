.wrapper {
  display: flex;
  align-items: center;
  height: 28px;
  box-sizing: border-box;
}

.email {
  margin-left: 13px;
  font-weight: 500;
  font-style: italic;
}
