.wrapper {
  position: relative;
  max-width: 1240px;
  margin: 195px 0;
  padding: 0 105px;
}

@media (max-width: 1023px) {
  .wrapper {
    margin-top: 80px;
    padding: 0 var(--mobileSidePadding);
  }
}

@media (max-width: 500px) {
  .wrapper {
    margin-top: 90px;
  }
}
