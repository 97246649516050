.content {
  width: 350px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../../shared/styles/components.module.css';
  margin-top: 20px;
  margin-bottom: 30px;
}

.alert {
  margin-top: 20px;
  margin-bottom: 0;
}

.inlineIconText {
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
}

.iconWrapper {
  width: 30px;
  margin-top: 3px;
  margin-right: 15px;
}

.passwordBox {
  margin-top: 20px;
}

.smallTip {
  composes: smallTip from '../../../components/shared/modal/content/edit-password/EditPassword.module.css';
  margin: 8px 0 20px;
}

.tipLink {
  composes: tipLink from '../../../components/shared/modal/content/edit-password/EditPassword.module.css';
}

.submit {
  width: 100% !important;
}

.divider {
  margin: 30px 0;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .wrapper {
    padding: 40px 30px;
  }

  .content {
    max-width: 350px;
    width: auto;
    margin-top: 70px;
  }
}
