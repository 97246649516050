.wrapper {
  display: flex;
  align-items: center;
}

.hr {
  width: 100%;
  height: 1px;
}

.text {
  margin: 0 45px;
}
