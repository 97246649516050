.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.svg {
  width: 100%;
  height: auto;
}

@media (max-width: 800px) {
  .svg {
    width: auto;
    height: 65px;
  }
}
