.icon {
  display: flex;
  max-width: 22px;
  max-height: 22px;
  margin-right: 15px;
}

@media (max-width: 459px) {
  .icon {
    margin-right: 10px;
  }
}
