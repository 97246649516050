.wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 12px 20px;
  min-height: 50px;
}

.icon {
  display: flex;
  margin-right: 20px;
}

.text {
  font-size: 1.4rem;
  font-weight: 500;
}
