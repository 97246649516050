.modalWrapper {
  position: relative;
  z-index: 10;
}

.overlay {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 75px 100px;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 8px;
}

.content {
  max-width: 320px;
}

.modalContentTitle {
  margin-bottom: 25px;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.6rem;
}

.modalContentDescription {
  font-weight: 400;
}

@media (max-width: 1023px) {
  .wrapper {
    min-width: 320px;
    padding: 75px 30px 50px;
  }
}

@media (max-width: 439px) {
  .wrapper {
    width: 310px;
  }
}

@media (max-width: 350px) {
  .wrapper {
    width: 100%;
    box-sizing: border-box;
  }
}
