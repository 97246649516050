.wrapper {
  --smallWrapper: 15px;
  --mediumWrapper: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  border-color: var(--white);
  animation: rotate 0.5s infinite linear;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  background-color: var(--white);
}

.wrapper.small {
  width: var(--smallWrapper);
  height: var(--smallWrapper);
  border-width: 2px;
  border-style: solid;
  border-radius: var(--smallWrapper);
}

.dot.small {
  width: calc(var(--smallWrapper) / 3);
  height: calc(var(--smallWrapper) / 3);
  border-radius: var(--smallWrapper);
}

.wrapper.medium {
  width: var(--mediumWrapper);
  height: var(--mediumWrapper);
  border-width: 2px;
  border-style: solid;
  border-radius: var(--mediumWrapper);
}

.dot.medium {
  width: calc(var(--mediumWrapper) / 3);
  height: calc(var(--mediumWrapper) / 3);
  border-radius: var(--mediumWrapper);
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
