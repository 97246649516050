.title {
  composes: pageTitle from '../../../shared/styles/components.module.css';
  margin-bottom: 25px;
}

.nextButton {
  width: 100% !important;
  margin-top: 10px;
}

.tosWrapper {
  font-size: 1.4rem;
  margin-top: 20px;
}

.tosLinkWrapper > * {
  display: inline;
}

.tosLink {
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
}

.textbox {
  margin-bottom: 25px;
}
