.content {
  width: 350px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../../shared/styles/components.module.css';
  margin-top: 20px;
  margin-bottom: 30px;
}

.iconTextWrapper {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.iconTextWrapper .text {
  margin-left: 10px;
  font-size: 2rem;
  font-weight: 600;
}

.alert {
  margin-top: 20px;
  margin-bottom: 0;
}

.authText {
  margin-top: 15px;
}

.submit {
  width: 100%;
  margin-top: 20px;
}

.divider {
  composes: divider from '../password/Password.module.css';
}

.modal {
  margin-left: 225px;
}

@media (max-width: 1699px) {
  .modal {
    margin-left: 175px;
  }
}

@media (max-width: 1023px) {
  .modal {
    margin-left: 0;
  }

  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .content {
    margin-top: 70px;
  }
}
