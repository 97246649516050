.pageTitle {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin-bottom: 100px;
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.row {
  display: grid;
  grid-template-columns: auto 230px 1fr 290px;
  grid-column-gap: 30px;
  align-items: center;
  font-weight: 500;
}

.row:not(:last-child) {
  margin-bottom: 30px;
}

.ipLabel {
  font-weight: 400;
}

.loadMore {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 50px auto 100px;
}

@media (max-width: 1299px) {
  .row {
    grid-template-columns: 20px 1fr minmax(155px, 215px) minmax(190px, 215px);
  }
}

@media (max-width: 1023px) {
  .pageTitle {
    margin-bottom: 60px;
  }
}

@media (max-width: 999px) {
  .row {
    grid-template-columns: 20px 1fr 1fr minmax(190px, 215px);
  }
}

@media (max-width: 699px) {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .cell:not(:last-child) {
    margin-bottom: 10px;
  }

  .cell:last-child {
    margin-bottom: 30px;
  }

  .cell:first-child {
    max-width: 25px;
  }

  .cell.date {
    margin-left: -15px;
  }

  .cell.system,
  .cell.ip {
    width: 100%;
    padding-left: 39px;
  }
}
