:root {
  --button-width: 24px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--button-width);
  height: 20px;
  padding: 3px 5px;
  border-radius: 2px;
  box-sizing: content-box;
}

.dropdown {
  position: absolute;
  z-index: 1;
  min-width: fit-content;
  margin-top: 17px;
  margin-left: 5px;
  visibility: hidden;
  overflow: hidden;
  background-color: var(--white);
  border-radius: 9px;
  box-shadow: 1.5px 2.5px 7px rgba(0, 0, 0, 0.2);
  transform: translateX(calc(-100% + var(--button-width)));
}

.visible {
  visibility: visible;
}
