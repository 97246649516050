.wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.checkboxWrapper {
  position: relative;
}

.checkboxInput {
  position: relative;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.checkboxInput + .checkbox {
  position: absolute;
  z-index: 0;
  box-sizing: border-box;
  left: 0;
  background: url('../../../assets/icons/checkboxCheckmark.svg') no-repeat
    center;
  background-size: 0 0;
  border: 1px solid;
  border-radius: 2px;
}

.checkbox.checkboxFocus {
  border: 2px solid;
  border-radius: 3px;
}

.checkboxInput,
.checkbox {
  top: 1px;
}

.checkboxInput:checked + .checkbox {
  background-size: 11px auto;
}

.checkboxInput:checked + .checkbox.checkboxFocus {
  border: 2px solid;
  border-radius: 3px;
}

.label {
  margin-left: 9px;
  cursor: pointer;
}
