.wrapper {
  position: relative;
  width: 87px;
  height: 105px;
}

@media (max-width: 800px) {
  .svg {
    width: auto;
    height: 100%;
  }
}
