.table {
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.row {
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr) auto;
  column-gap: 80px;
  white-space: nowrap;
}

.row:not(:first-child) {
  margin-top: 20px;
}

.row:not(:first-child) {
  margin-top: 20px;
}

.item:first-child {
  font-weight: 700;
}

.item:not(.description):not(.label) {
  font-size: 1.6rem;
}

.label {
  height: fit-content;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  line-height: initial;
}

.optionsButton {
  justify-self: end;
}

@media (max-width: 1850px) {
  .row {
    column-gap: 50px;
  }
}

@media (max-width: 1750px) {
  .row {
    white-space: normal;
  }
}

@media (max-width: 1450px) {
  .row {
    grid-template-columns: 100px 205px 180px auto;
    row-gap: 15px;
    column-gap: 80px;
  }

  .row:not(:first-child) {
    margin-top: 35px;
  }

  .row.noCategory {
    grid-template-columns: auto auto auto 0;
    gap: 0;
  }

  .item:first-child {
    grid-row: 1;
    grid-column: 1 / 5;
  }
}

@media (max-width: 1199px) {
  .row {
    grid-template-columns: minmax(auto, 100px) 0 205px auto;
    column-gap: 40px;
  }

  .row:not(.noCategory) .item:nth-child(3) {
    height: 0;
    visibility: hidden;
  }

  .item:not(.description):not(.label) {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .row {
    /* grid-template-columns: minmax(auto, 58px) 0 205px auto; */
    column-gap: 10px;
  }

  .row.noCategory {
    grid-template-columns: 1fr 0 auto;
    gap: 0;
  }

  .row.noCategory .item:nth-child(3) {
    height: 0;
    visibility: hidden;
  }

  .label {
    padding: 5px;
  }
}

@media (max-width: 400px) {
  /* TODO: the grid layout should be refactored and simplified */

  .row > .item:nth-child(2) {
    max-width: 230px;
  }

  .row > .item:nth-child(3) {
    display: none;
  }
}
