.title {
  composes: title from '../LoginPage.module.css';
}

.link {
  composes: link from '../LoginPage.module.css';
}

.linkWrapper {
  composes: linkWrapper from '../LoginPage.module.css';
}

.alert {
  margin-top: 20px;
  box-sizing: border-box;
}
