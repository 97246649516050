.header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 10;
  height: 80px;
  padding: 0 var(--mobileSidePadding);
  color: var(--white);
}

.logoSmall {
  display: flex;
  justify-content: center;
  width: auto;
  height: 31px;
}

.logout {
  padding: 10px 0 10px;
  padding-right: 0;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
}

.backButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 10px;
  box-sizing: border-box;
  border: 2px solid var(--white);
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 500;
}

.backIcon {
  width: auto;
  height: 15px;
  margin-right: 15px;
}

.burgerButtonWrapper {
  display: none;
}

@media (max-width: 1023px) {
  .burgerButtonWrapper {
    display: block;
    width: 38px;
    height: 21px;
  }
}
