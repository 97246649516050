.wrapper {
  position: relative;
  padding: 40px 50px;
}

.signUpCta {
  margin-top: 15px;
  font-size: 1.4rem;
  text-align: center;
}

.ctaLink {
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 500;
}

.ctaLink > * {
  text-decoration: underline;
}

.content {
  width: 350px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin-bottom: 35px;
}

.signInButton {
  width: 100% !important;
  margin-top: 20px;
}

.title {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin: 20px 0 30px;
  font-weight: 700;
  line-height: 3.6rem;
}

.link {
  display: block;
  margin-left: auto;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
}

.linkWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.fullWidth {
  width: 100% !important;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .wrapper {
    padding: 40px var(--mobileSidePadding);
  }

  .content {
    max-width: 350px;
    width: auto;
    margin-top: 5px;
  }
}
