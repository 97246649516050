.wrapper {
  position: relative;
  padding: 40px 50px;
}

.content {
  width: 350px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin: 20px 0;
  line-height: 3.6rem;
}

.button {
  width: 100% !important;
  margin-top: 30px;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .wrapper {
    padding: 40px var(--mobileSidePadding);
  }

  .content {
    margin-top: 5px;
  }
}
