.title {
  composes: title from '../LoginPage.module.css';
}

.link {
  composes: link from '../LoginPage.module.css';
}

.linkWrapper {
  composes: linkWrapper from '../LoginPage.module.css';
}

.continueButton {
  width: 100% !important;
  margin-top: 10px;
}

.divider {
  margin: 20px 30px 30px 30px;
}

.disableTooltip {
  margin-top: 10px;
}

.hint {
  margin-top: 15px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: justify;
}
