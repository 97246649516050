.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
}

.pageTitle {
  composes: pageTitle from '../../shared/styles/components.module.css';
}

.securityBoard {
  display: flex;
  margin-right: 45px;
}

.securityList {
  margin-top: 5px;
  margin-right: 50px;
}

.securityListRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 22px;
}

.securityListRow:not(:last-child) {
  margin-bottom: 7px;
}

.securityTerm {
  font-weight: 500;
}

.securityType {
  font-weight: 500;
}

.badgeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge {
  width: 90px;
  height: 115px;
  margin-bottom: 10px;
}

.badgeLink {
  display: block;
  margin-bottom: 20px;
  font-weight: 500;
  text-decoration: underline;
}

.contentCtaWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contentHeader {
  margin-top: 13px;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
}

.accountButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.accountButton {
  margin-bottom: 0;
  text-align: center;
}

.accountLink {
  margin-bottom: 20px;
}

.historyLinkWrapper {
  display: flex;
  align-items: center;
  margin-top: 25px;
  font-weight: 400;
}

.historyLinkText {
  margin-left: 12px;
}

.inlineCta {
  display: flex;
  justify-content: space-between;
}

.inlineCta > :not(:last-child) {
  margin-right: 15px;
}

.cta {
  max-width: 160px;
  padding: 0px 10px !important;
}

.leftColumn {
  max-width: 800px;
}

.smallText {
  margin: 15px 0;
  font-size: 1.4rem;
  font-weight: 400;
}

.buttonStyle {
  display: flex;
  justify-content: center;
  max-height: 50px;
  font-weight: 500 !important;
}

@media (max-width: 1199px) {
  .pageTitle {
    line-height: 3.5rem;
  }

  .securityList {
    display: none;
  }

  .optionDropdown {
    padding: 3px 5px;
  }

  .mobileSectionHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.inlineButton {
  width: 100px;
  padding: 10px 18px !important;
}

.fluidButton {
  width: 100% !important;
  padding: 10px 18px !important;
}

@media (max-width: 1023px) {
  .header {
    margin-bottom: 50px;
  }

  .contentCtaWrapper {
    flex-direction: column;
  }

  .contentHeader {
    margin-top: 0;
  }

  .accountButton,
  .accountLink {
    width: 100% !important;
  }

  .accountButton {
    margin-bottom: 0;
  }

  .accountLink {
    margin-bottom: 20px;
  }
}

@media (max-width: 499px) {
  .securityBoard {
    margin-right: 0;
  }
}

@media (max-width: 399px) {
  .badgeWrapper {
    text-align: center;
  }
}
