@import '../../../shared/styles/colors.module.css';
@import '../../../shared/styles/components.module.css';

.App {
  position: relative;
  display: flex;
  min-height: 100vh;
}

@media (max-width: 1023px) {
  :root {
    --mobileSidePadding: 16px;
  }

  .App {
    flex-direction: column;
  }
}
