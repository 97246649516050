.title {
  font-size: inherit;
  font-weight: 700;
  line-height: 3.5rem;
}

.title.withIcon {
  margin: 0;
  padding-left: 13px;
  width: 100%;
}

.titleIconWrapper {
  display: flex;
  min-height: 28px;
  margin: 20px 0 20px;
}

.icon {
  width: 28px;
  height: 28px;
  float: left;
  margin-top: 3px;
}
