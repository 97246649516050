.title {
  composes: modalContentTitle from '../../Modal.module.css';
}

.smallTip {
  font-size: 1.4rem;
  font-weight: 400;
}

.tipLink {
  margin-left: 5px;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: underline;
}
