.title {
  composes: modalContentTitle from '../../Modal.module.css';
}

.description {
  composes: modalContentDescription from '../../Modal.module.css';
}

.checkbox {
  margin-top: 30px;
}
