.buttonStyle {
  composes: button from '../../../components/shared/button/Button.module.css';
  position: relative;
  height: 50px;
  padding: 10px 23px !important;
  font-weight: 500;
  transition: all 0.08s;
  text-align: center;
}

.iconWrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 30px;
  pointer-events: none;
}

.divider {
  width: 1px;
  height: 100%;
}

.loading {
  position: absolute;
  right: 25px;
  pointer-events: none;
}

.buttonStyle.disabled:hover {
  box-shadow: none !important;
  cursor: default;
}
