.wrapper {
  position: relative;
  padding: 40px 50px;
}

.content {
  width: 600px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin-top: 20px;
}

.titleDivider {
  width: 100%;
  height: 1px;
  margin-top: 9px;
  margin-bottom: 45px;
  border: none;
}

.text {
  margin: 35px 0 40px 0;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 290px !important;
}

.dontAskButton {
  display: block;
  margin: 40px auto 0;
  font-weight: 500;
  font-size: 1.6rem;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 680px) {
  .buttonWrapper {
    flex-direction: column-reverse;
  }

  .button {
    width: 100% !important;
  }

  .content {
    max-width: 350px;
  }

  .button:last-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 449px) {
  .content {
    width: auto;
    margin-top: 5px;
  }

  .text {
    line-height: 2.5rem;
  }
}
