:root {
  --textboxBorder: 2px;
}

.textboxWrapper {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 10px;
}

.passwordStrengthIndicator {
  position: absolute;
  z-index: -1;
  width: 20%;
  height: 2px;
  transform: translate(
    var(--textboxBorder),
    calc(-100% - var(--textboxBorder))
  );
  border-bottom-left-radius: calc(var(--textboxBorder) / 2);
  transition: all 0.2s;
}

.passwordStrengthIndicator.off {
  width: 0;
}

.passwordStrengthIndicator.weak {
  width: 45%;
}

.passwordStrengthIndicator.okay {
  width: 60%;
}

.passwordStrengthIndicator.good {
  width: 80%;
}

.passwordStrengthIndicator.strong {
  width: calc(100% - calc(var(--textboxBorder) * 2));
}

.passwordStrengthIndicator.green {
  border-bottom-right-radius: calc(var(--textboxBorder) / 2);
}

.passwordStrengthLabel {
  font-weight: 500;
}

.showPassword {
  position: absolute;
  display: flex;
  top: 50%;
  right: 10px;
  z-index: 1;
  transform: translateY(-50%);
  padding: 3px 6px 0;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  height: 22px;
  margin-bottom: 7px;
  font-size: 1.4rem;
}
