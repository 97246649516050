.button {
  --paddingW: 30px;
  --paddingH: 10px;
  position: relative;
  text-align: center;
}

.button:not(.linkStyle):not(.noStyle) {
  width: fit-content;
  min-width: 220px;
  height: fit-content;
  min-height: 50px;
  padding: var(--paddingH) var(--paddingW);
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  font-size: 1.6rem;
  font-weight: 500;
}

.button:not(.linkStyle):not(.noStyle):focus-visible {
  outline: none;
  border: 2px solid !important;
}

.button:not(.disabled):hover {
  cursor: pointer;
}

.button:not(.linkStyle):not(.disabled):not(.noStyle):hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.linkStyle {
  padding: 0 8px;
  background: none;
  border: none;
  text-decoration: underline;
}

.dropdownStyle {
  width: 100%;
  min-width: 175px;
  padding: 16px !important;
  box-sizing: border-box;
  color: inherit;
  font-weight: 400;
  text-align: left;
  background: none;
  font-size: inherit;
  border: none;
}

.noStyle {
  background: none;
  border: none;
  font-size: inherit;
}

.iconWrapper {
  --iconWidth: 23px;
  display: flex;
  width: var(--iconWidth);
  height: auto;
  justify-content: space-between;
}

.icon {
  position: absolute;
  left: auto;
  display: flex;
  width: 23px;
}

.iconDivider {
  position: absolute;
  top: 50%;
  margin-left: calc(var(--iconWidth) - 1px);
  width: 1px;
  height: calc(100% - var(--paddingH) * 2);
  transform: translateY(-50%);
  background-color: var(--white);
}
