.title {
  composes: title from '../LoginPage.module.css';
}

.divider {
  margin: 20px 30px 30px 30px;
}

.fluidButton {
  width: 100% !important;
}
