.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
}

.submitButton {
  min-width: 120px !important;
}

.backButton {
  height: max-content;
  margin-right: 30px;
  font-size: 1.6rem;
  font-weight: 500;
}
