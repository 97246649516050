.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 515px;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badgeWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rightTitle {
  margin-top: 20px;
  font-weight: 500;
}

.title {
  margin-bottom: 25px;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}

.item {
  display: flex;
  align-items: flex-start;
  font-size: 1.4rem;
  font-weight: 500;
}

.item:not(:last-child) {
  margin-bottom: 15px;
}

@media (max-width: 500px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    padding: 30px 25px;
  }

  .rightColumn {
    margin-top: 40px;
    margin-left: 0;
  }
}
