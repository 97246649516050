.listButton > * {
  width: 100%;
  /*composes: fullWidth from '../LoginPage.module.css';*/
}

.listButton:not(:last-child) {
  margin-bottom: 20px;
}

.divider {
  margin: 25px 0 25px 0;
}

.fluidButton {
  width: 100% !important;
}
