.wrapper {
  position: relative;
}

.wrapper > *:not(.tooltipWrapper) {
  width: 100%;
}

.tooltipWrapper {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, calc(-100% - 10px));
  width: max-content;
  max-width: 210px;
  box-sizing: border-box;
  line-height: 1.9rem;
  padding: 5px 13px;
  border-radius: 6px;
  color: var(--white);
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
}
