.buttonStyle {
  composes: button from '../button/Button.module.css';
  position: relative;
  display: flex;
  align-items: center;
  height: 60px !important;
  padding: 20px 25px !important;
  font-weight: 700 !important;
  transition: all 0.08s;
}

.wrapper:focus-visible {
  outline: none;
}

.wrapper > * {
  padding: 2px;
}

.wrapper:focus-visible > * {
  padding: 0;
  border: 2px solid !important;
}

.wrapper.disabled:hover * {
  cursor: default;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-right: 20px;
  pointer-events: none;
}

.arrow {
  position: absolute;
  right: 25px;
  pointer-events: none;
}

.arrow * {
  width: 6px;
  height: 11px;
}

.buttonStyle.disabled:hover {
  box-shadow: none !important;
}
