.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding-bottom: 5px;
  border-bottom: 1px solid;
  font-size: 2.5rem;
}

.header:not(.smallTitle) {
  padding-bottom: 20px;
}

.header.noBorder {
  border-bottom: none;
}

.header.hasSidenote {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hasButton {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.smallTitle {
  font-size: 1.6rem;
}

.headerCta {
  margin-left: 20px;
}

.sideNote {
  margin-left: 8px;
  font-size: 1.4rem;
  font-weight: 400;
}

@media (max-width: 599px) {
  .headerCta {
    max-width: 100px;
  }
}

@media (max-width: 459px) {
  .header:not(.smallTitle) {
    padding-bottom: 20px;
  }
}
