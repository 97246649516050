.wrapper {
  position: relative;
  padding: 40px 50px;
}

.email {
  font-style: italic;
  height: 28px;
}

.signUpCta {
  position: absolute;
  right: 50px;
  font-size: 1.4rem;
}

.ctaLink {
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 500;
}

.ctaLink > * {
  text-decoration: underline;
}

.content {
  width: 350px;
  margin: 200px auto 0;
}

.title {
  composes: pageTitle from '../../shared/styles/components.module.css';
  margin: 20px 0;
}

.signInButton {
  width: 100%;
  margin-top: 20px;
}

.hr {
  margin: 30px 0;
}

.fidoButtonWrapper {
  position: relative;
}

.buttonIconWrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  left: 18px;
  width: 30px;
  height: 34px;
  transform: translateY(-50%);
  pointer-events: none;
}

.iconDivider {
  width: 1px;
  height: 100%;
  background-color: var(--white);
}

.fidoButton {
  width: 100%;
}

@media (max-width: 1023px) {
  .content {
    margin-top: 100px;
  }
}

@media (max-width: 449px) {
  .wrapper {
    padding: 40px 30px;
  }

  .content {
    max-width: 350px;
    width: auto;
    margin-top: 5px;
  }

  .signUpCta {
    right: 30px;
  }
}

.smallTip {
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 400;
}

.tipLink {
  margin-left: 5px;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: underline;
  color: #3e464c;
}

.submit {
  width: 100% !important;
}
