.title {
  composes: modalContentTitle from '../../Modal.module.css';
}

.description {
  composes: modalContentDescription from '../../Modal.module.css';
}

.appsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.qrCode {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

.textbox {
  margin-top: 30px;
}

.backButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  composes: backButton from '../button-wrapper/ButtonWrapper.module.css';
  width: 60px;
  padding-left: 0;
}

.step3Submit {
  margin: 50px 0 0 auto;
}
